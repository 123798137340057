/*!

=========================================================
* Paper Kit React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
.landingPage-layout {
  .tim-row {
    margin-bottom: 20px;
  }

  .tim-white-buttons {
    background-color: #777777;
  }

  .typography-line {
    padding-left: 25%;
    margin-bottom: 35px;
    position: relative;
    display: block;
    width: 100%;
  }

  .typography-line span {
    bottom: 10px;
    color: #c0c1c2;
    display: block;
    font-weight: 400;
    font-size: 13px;
    line-height: 13px;
    left: 0;
    margin-left: 20px;
    position: absolute;
    width: 260px;
    text-transform: none;
  }

  .tim-row {
    padding-top: 60px;
  }

  .tim-row h3 {
    margin-top: 0;
  }

  .switch {
    margin-right: 20px;
  }

  #navbar-full .navbar {
    border-radius: 0 !important;
    margin-bottom: 15px;
    z-index: 2;
  }

  #menu-dropdown .navbar {
    border-radius: 3px;
  }

  #pagination-row .pagination-container {
    height: 100%;
    max-height: 100%;
    display: flex;
    align-items: center;
  }

  /* Nucelo Icons */

  #icons-row i.nc-icons {
    font-size: 30px;
  }

  .demo-iconshtml {
    font-size: 62.5%;
  }

  .demo-icons body {
    font-size: 1.6rem;
    font-family: sans-serif;
    color: #333333;
    background: white;
  }

  .demo-icons a {
    color: #608cee;
    text-decoration: none;
  }

  .demo-icons header {
    text-align: center;
    padding: 100px 0 0;
  }

  .demo-icons header h1 {
    font-size: 2.8rem;
  }

  .demo-icons header p {
    font-size: 1.4rem;
    margin-top: 1em;
  }

  .demo-icons header a:hover {
    text-decoration: underline;
  }

  .demo-icons .nc-icon {
    font-size: 34px;
  }

  .demo-icons section {
    width: 90%;
    max-width: 1200px;
    margin: 50px auto;
  }

  .demo-icons section h2 {
    border-bottom: 1px solid #e2e2e2;
    padding: 0 0 1em 0.2em;
    margin-bottom: 1em;
  }

  .demo-icons ul::after {
    clear: both;
    content: '';
    display: table;
  }

  .demo-icons ul li {
    width: 20%;
    float: left;
    padding: 16px 0;
    text-align: center;
    border-radius: 0.25em;
    -webkit-transition: background 0.2s;
    -moz-transition: background 0.2s;
    transition: background 0.2s;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;
  }

  .demo-icons ul li:hover {
    background: #f4f4f4;
  }

  .demo-icons ul p,
  .demo-icons ul em,
  .demo-icons ul input {
    display: inline-block;
    font-size: 1rem;
    color: #999999;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    -ms-user-select: auto;
    user-select: auto;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
  }

  .demo-icons ul p {
    padding: 20px 0 0;
    font-size: 12px;
    margin: 0;
  }

  .demo-icons ul p::selection,
  .demo-icons ul em::selection {
    background: #608cee;
    color: #efefef;
  }

  .demo-icons ul em {
    font-size: 12px;
  }

  .demo-icons ul em::before {
    content: '[';
  }

  .demo-icons ul em::after {
    content: ']';
  }

  .demo-icons ul input {
    text-align: center;
    background: transparent;
    border: none;
    box-shadow: none;
    outline: none;
    display: none;
  }

  .space {
    height: 130px;
    display: block;
  }

  .space-110 {
    height: 110px;
    display: block;
  }

  .space-50 {
    height: 50px;
    display: block;
  }

  .space-70 {
    height: 70px;
    display: block;
  }

  .navigation-example {
    background-attachment: scroll;
  }

  .navigation-example {
    background-position: center center;
    background-size: cover;
    margin-top: 0;
    height: 100%;
  }

  #notifications {
    background-color: #ffffff;
    display: block;
    width: 100%;
    position: relative;
  }

  .tim-note {
    text-transform: capitalize;
  }

  .download-area {
    margin-top: 30px;
  }

  .sharing-area {
    margin-top: 50px;
  }

  .sharing-area .btn {
    margin-top: 14px;
  }

  .upgrade-pro .btn {
    margin-top: 30px;
  }

  #buttons .btn,
  #javascriptComponents .btn {
    margin: 0 0px 10px;
  }

  .space-100 {
    height: 100px;
    display: block;
    width: 100%;
  }

  .be-social {
    padding-bottom: 20px;
    /*     border-bottom: 1px solid #aaa; */
    margin: 0 auto 40px;
  }

  .txt-white {
    color: #ffffff;
  }

  .txt-gray {
    color: #ddd !important;
  }

  .parallax {
    width: 100%;
    height: 570px;
    display: block;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .profile-content {
    padding-top: 0;
    position: relative;
    z-index: 2;
  }

  .logo-container .logo {
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #333333;
    width: 50px;
    float: left;
  }

  .logo-container .brand {
    font-size: 16px;
    color: #ffffff;
    line-height: 18px;
    float: left;
    margin-left: 10px;
    margin-top: 7px;
    width: 70px;
    height: 40px;
    text-align: left;
  }

  .logo-container .brand-material {
    font-size: 18px;
    margin-top: 15px;
    height: 25px;
    width: auto;
  }

  .logo-container .logo img {
    width: 100%;
  }

  .navbar-small .logo-container .brand {
    color: #333333;
  }

  .fixed-section {
    top: 90px;
    max-height: 80vh;
    overflow: scroll;
    position: sticky;
  }

  .fixed-section ul {
    padding: 0;
  }

  .fixed-section ul li {
    list-style: none;
  }

  .fixed-section li a {
    font-size: 14px;
    padding: 2px;
    display: block;
    color: #666666;
  }

  .fixed-section li a.active {
    color: #00bbff;
  }

  .fixed-section.float {
    position: fixed;
    top: 100px;
    width: 200px;
    margin-top: 0;
  }

  .parallax .parallax-image {
    width: 100%;
    overflow: hidden;
    position: absolute;
  }

  .parallax .parallax-image img {
    width: 100%;
  }

  @media (max-width: 768px) {
    .parallax .parallax-image {
      width: 100%;
      height: 640px;
      overflow: hidden;
    }
    .parallax .parallax-image img {
      height: 100%;
      width: auto;
    }
  }

  /*.separator{
    content: "Separator";
    color: #FFFFFF;
    display: block;
    width: 100%;
    padding: 20px;
}
.separator-line{
    background-color: #EEE;
    height: 1px;
    width: 100%;
    display: block;
}
.separator.separator-gray{
    background-color: #EEEEEE;
}*/

  .social-buttons-demo .btn {
    margin-right: 5px;
    margin-bottom: 7px;
  }

  .img-container {
    width: 100%;
    overflow: hidden;
  }

  .img-container img {
    width: 100%;
  }

  .lightbox img {
    width: 100%;
  }

  .lightbox .modal-content {
    overflow: hidden;
  }

  .lightbox .modal-body {
    padding: 0;
  }

  @media screen and (min-width: 991px) {
    .lightbox .modal-dialog {
      width: 960px;
    }
  }

  @media (max-width: 991px) {
    .fixed-section.affix {
      position: relative;
      margin-bottom: 100px;
    }
  }

  @media (max-width: 768px) {
    .btn,
    .btn-morphing {
      margin-bottom: 10px;
    }
    .parallax .motto {
      top: 170px;
      margin-top: 0;
      font-size: 60px;
      width: 270px;
    }
  }

  /*       Loading dots  */

  /*      transitions */

  .presentation .front,
  .presentation .front:after,
  .presentation .front .btn,
  .logo-container .logo,
  .logo-container .brand {
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
  }

  #images h4 {
    margin-bottom: 30px;
  }

  #javascriptComponents {
    padding-bottom: 0;
  }

  /*      layer animation          */

  .layers-container {
    display: block;
    margin-top: 50px;
    position: relative;
  }

  .layers-container img {
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    text-align: center;
  }

  .animate {
    transition: 1.5s ease-in-out;
    -moz-transition: 1.5s ease-in-out;
    -webkit-transition: 1.5s ease-in-out;
  }

  .navbar-default.navbar-small .logo-container .brand {
    color: #333333;
  }

  .navbar-transparent.navbar-small .logo-container .brand {
    color: #ffffff;
  }

  .navbar-default.navbar-small .logo-container .brand {
    color: #333333;
  }

  .sharing-area {
    margin-top: 80px;
  }

  .sharing-area .btn {
    margin: 15px 4px 0;
  }

  .section-thin,
  .section-notifications {
    padding: 0;
  }

  .section-navbars {
    padding-top: 0;
  }

  .section-tabs {
    background: #eeeeee;
  }

  .section-pagination {
    padding-bottom: 0;
  }

  .section-download {
    padding-top: 130px;
  }

  .section-download .description {
    margin-bottom: 60px;
  }

  .section-download h4 {
    margin-bottom: 25px;
  }

  .section-examples a {
    text-decoration: none;
  }

  .section-examples a + a {
    margin-top: 30px;
  }

  .section-examples h5 {
    margin-top: 30px;
  }

  .components-page .wrapper > .header,
  .tutorial-page .wrapper > .header {
    height: 500px;
    padding-top: 128px;
    background-size: cover;
    background-position: center center;
  }

  .components-page .title,
  .tutorial-page .title {
    color: #ffffff;
  }

  .brand .h1-seo {
    font-size: 2.8em;
    text-transform: uppercase;
    font-weight: 300;
  }

  .brand .n-logo {
    max-width: 100px;
    margin-bottom: 40px;
  }

  .invision-logo {
    max-width: 70px;
    top: -2px;
    position: relative;
  }

  .creative-tim-logo {
    max-width: 140px;
    top: -2px;
    position: relative;
  }

  .section-javascript .title {
    margin-bottom: 0;
  }

  .navbar .switch-background {
    display: block;
  }

  .navbar-transparent .switch-background {
    display: none;
  }

  .section-signup .col .btn {
    margin-top: 30px;
  }

  #buttons-row .btn {
    margin-bottom: 10px;
  }

  .section-basic {
    padding-top: 0;
  }

  .section-images {
    padding-bottom: 0;
  }

  @media screen and (max-width: 991px) {
    .section-navbars .navbar-collapse {
      display: none !important;
    }
  }
}
