/*
 =========================================================
 * Black Dashboard React v1.0.0 based on Black Dashboard - v1.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard-react
 * Copyright 2019 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Core
.black_dashboard {
  @import 'black-dashboard-react/custom/functions';
  @import 'black-dashboard-react/bootstrap/functions';

  @import 'black-dashboard-react/custom/variables';
  @import 'black-dashboard-react/bootstrap/variables';

  @import 'black-dashboard-react/custom/mixins';
  @import 'black-dashboard-react/bootstrap/mixins';

  // Bootstrap components

  @import 'black-dashboard-react/bootstrap/root';
  @import 'black-dashboard-react/bootstrap/reboot';
  @import 'black-dashboard-react/bootstrap/type';
  @import 'black-dashboard-react/bootstrap/images';
  @import 'black-dashboard-react/bootstrap/code';
  @import 'black-dashboard-react/bootstrap/grid';
  @import 'black-dashboard-react/bootstrap/tables';
  @import 'black-dashboard-react/bootstrap/forms';
  @import 'black-dashboard-react/bootstrap/buttons';
  @import 'black-dashboard-react/bootstrap/transitions';
  @import 'black-dashboard-react/bootstrap/dropdown';
  @import 'black-dashboard-react/bootstrap/button-group';
  @import 'black-dashboard-react/bootstrap/input-group';
  @import 'black-dashboard-react/bootstrap/custom-forms';
  @import 'black-dashboard-react/bootstrap/nav';
  @import 'black-dashboard-react/bootstrap/navbar';
  @import 'black-dashboard-react/bootstrap/card';
  @import 'black-dashboard-react/bootstrap/breadcrumb';
  @import 'black-dashboard-react/bootstrap/pagination';
  @import 'black-dashboard-react/bootstrap/badge';
  @import 'black-dashboard-react/bootstrap/jumbotron';
  @import 'black-dashboard-react/bootstrap/alert';
  @import 'black-dashboard-react/bootstrap/progress';
  @import 'black-dashboard-react/bootstrap/media';
  @import 'black-dashboard-react/bootstrap/list-group';
  @import 'black-dashboard-react/bootstrap/close';
  @import 'black-dashboard-react/bootstrap/modal';
  @import 'black-dashboard-react/bootstrap/tooltip';
  @import 'black-dashboard-react/bootstrap/popover';
  @import 'black-dashboard-react/bootstrap/carousel';
  @import 'black-dashboard-react/bootstrap/utilities';
  @import 'black-dashboard-react/bootstrap/print';

  // Custom components

  @import 'black-dashboard-react/custom/alerts.scss';
  @import 'black-dashboard-react/custom/badges.scss';
  @import 'black-dashboard-react/custom/buttons.scss';
  @import 'black-dashboard-react/custom/dropdown.scss';
  @import 'black-dashboard-react/custom/footer.scss';
  @import 'black-dashboard-react/custom/forms.scss';
  @import 'black-dashboard-react/custom/images.scss';
  @import 'black-dashboard-react/custom/modal.scss';
  @import 'black-dashboard-react/custom/navbar.scss';
  @import 'black-dashboard-react/custom/type.scss';
  @import 'black-dashboard-react/custom/tables';
  @import 'black-dashboard-react/custom/checkboxes-radio';
  @import 'black-dashboard-react/custom/fixed-plugin';
  @import 'black-dashboard-react/custom/sidebar-and-main-panel.scss';
  @import 'black-dashboard-react/custom/misc.scss';
  @import 'black-dashboard-react/custom/rtl.scss';
  @import 'black-dashboard-react/custom/input-group.scss';

  // Vendor / Plugins

  @import 'black-dashboard-react/custom/vendor/plugin-perfect-scrollbar.scss';
  @import 'black-dashboard-react/custom/vendor/plugin-react-notification-alert.scss';

  // light mode

  @import 'black-dashboard-react/custom/white-content.scss';

  // Cards

  @import 'black-dashboard-react/custom/card';
  @import 'black-dashboard-react/custom/cards/card-chart';
  @import 'black-dashboard-react/custom/cards/card-map';
  @import 'black-dashboard-react/custom/cards/card-user';
  @import 'black-dashboard-react/custom/cards/card-task';
  @import 'black-dashboard-react/custom/cards/card-plain';
}
